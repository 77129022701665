import { StudyData } from "pages/home-page/components/study-table/studyTable.types";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import { reportMessage } from "services/system";
import {
  decodeDateString,
  decodeNameFromHL7,
  decodeStudyDate,
} from "utils/formatting/decodeHL7";
import { setUpHeaderAndFooter } from "./functions/setUpHeaderAndFooter";
import {
  getInstitutionTemplate,
  updateStudyReportRefIds,
} from "services/studies/studies";

import { setDocumentPermissions } from "./functions/setUpDocumentPermissions";
import { REPORT_PERMISSION } from "./googleDocs.consts";
import { REPORT_TYPES } from "pages/home-page/components/study-table/study-table-rows/studyTableRows.consts";
import { setUpContentAndStyles } from "./functions/setUpContentAndStyle";
import { googleServiceAccountToken } from "services/auth";

const getPatientDetailsFromStudyData = (studyData: StudyData) => {
  const {
    studyDatetime,
    patName,
    patBirthdate,
    patSex,
    medRepIds,
    refRepIds,
    physician,
    institution,
    patId,
  } = studyData;

  const formattedData = {
    studyDate: decodeStudyDate(studyDatetime),
    patientName: decodeNameFromHL7(patName),
    patientId: patId,
    institution,
    patientSex: patSex,
    patientDob: decodeDateString(patBirthdate),
    referringDoctor: decodeNameFromHL7(physician),
    medRepIds,
    refRepIds,
  };

  return formattedData;
};

const setUpReportTemplate = (patientData, reportType) => {
  const { patientName, patientId, institution, medRepIds, refRepIds } =
    patientData;
  // The original code had no differentiation - in future if we need to build that out we can
  return {
    reportTemplateBody: {},
    fileName: `Report for ${patientName}  - Patient ID ${patientId} - ${institution}`,
    existingReportId:
      reportType === REPORT_TYPES.MEDICAL
        ? medRepIds ?? null
        : refRepIds ?? null,
  };
};

export const createReport = async (
  studyData: StudyData,
  reportType,
  userId
) => {
  const patientParameters = getPatientDetailsFromStudyData(studyData);
  const { fileName } = setUpReportTemplate(patientParameters, reportType);

  // const savedGoogleAccessToken = secureLocalStorage.getItem(
  //   "googleAccessToken"
  // ) as { googleAccessToken: string };

  const { createDocumentResult, googleAccessToken } =
    await createDocumentWithServiceAccount(fileName);
  const responseData = await createDocumentResult.json();
  const { documentId } = responseData;
  if (documentId) {
    const documentPermissionResult = await setDocumentPermissions(
      documentId,
      googleAccessToken,
      REPORT_PERMISSION
    );
    if (!documentPermissionResult.ok) {
      const errorMessage = `Error setting the document permission for ${studyData.studypk}`;
      toast.error(errorMessage);
      reportMessage(userId, errorMessage);
    }
    const { headerId, footerId, errorMessage } = await setUpHeaderAndFooter(
      documentId,
      googleAccessToken
    );
    if (errorMessage) {
      toast.error(errorMessage);
      reportMessage(userId, errorMessage);
    }
    const siteInfo = await getInstitutionTemplate(
      patientParameters.institution
    );
    await setUpContentAndStyles(
      reportType,
      patientParameters,
      siteInfo,
      headerId,
      footerId,
      documentId,
      googleAccessToken,
      userId
    );
    const updatedRefIds = await updateStudyReportRefIds(
      studyData,
      reportType,
      documentId
    );
    if (!updatedRefIds.success) {
      const errorMessage = `Error updating the ref ids for ${studyData.studypk}`;
      toast.error(errorMessage);
      reportMessage(userId, errorMessage);
    }
    window.open(
      "https://docs.google.com/document/d/" + documentId + "/edit",
      "_blank"
    );
    return documentId;
  } else {
    const errorMessage = `Error setting the document permission for ${studyData.studypk}`;
    toast.error(errorMessage);
    reportMessage(userId, errorMessage);
  }
  // } else {
  //   window.location.href = "/google-connect";
  // }
};

const createDocumentWithServiceAccount = async (fileName) => {
  const { accessToken } = await googleServiceAccountToken();

  const createDocumentResult = await fetch(
    "https://docs.googleapis.com/v1/documents?title=" + fileName,
    {
      method: "POST",
      headers: new Headers({
        Authorization: "Bearer " + accessToken,
      }),
    }
  );

  return {
    createDocumentResult: createDocumentResult,
    googleAccessToken: accessToken,
  };
};
const createGoogleDocAndGetAccessToken = async (
  savedGoogleAccessToken,
  fileName
) => {
  const { googleAccessToken } = savedGoogleAccessToken;
  let googleApiToken = googleAccessToken;
  let createDocumentResult = await fetch(
    "https://docs.googleapis.com/v1/documents?title=" + fileName,
    {
      method: "POST",
      headers: new Headers({
        Authorization: "Bearer " + googleApiToken,
      }),
    }
  );
  let returnedResult;
  if (!createDocumentResult.ok) {
    const { accessToken } = await googleServiceAccountToken();
    googleApiToken = accessToken;
    const createDocumentResult2 = await fetch(
      "https://docs.googleapis.com/v1/documents?title=" + fileName,
      {
        method: "POST",
        headers: new Headers({
          Authorization: "Bearer " + googleApiToken,
        }),
      }
    );
    if (!createDocumentResult2.ok) {
      const errorMessage = `Error creating new document ${createDocumentResult2.status}`;
      toast.error(errorMessage);
    }
    returnedResult = createDocumentResult.ok
      ? createDocumentResult
      : createDocumentResult2;
  }

  return {
    createDocumentResult: returnedResult,
    googleAccessToken: googleApiToken,
  };
};
